import _ from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import { AwesomeButton } from 'react-awesome-button'
import themeGet from '@styled-system/theme-get'
import "react-awesome-button/dist/styles.css";

const Button = styled(AwesomeButton)`
  margin: 14px 3px !important;
  border: 0 !important;
  .aws-btn__content {
    padding: 0 14px !important;
    & > span {
      height: 100%;
    }
  }
  img {
    width: 22px;
    height: 88%;
    margin: 0;
    border: 0px solid !important;
    position: relative;
    top: 2px;
  }
`

const Container = styled.div`
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-wrap: wrap;
  padding-top: 0px;
  padding-bottom: 15px;
  justify-content: center;
`

const ShareTitle = styled.span`
  width: 100%;
  text-align: center;
  font-weight: 600;
  font-size: 0.85em;
  line-height: 0;
  margin-bottom: 20px;
  color: ${themeGet('colors.contrast.3')};
`

const isMobileOrTablet = () => {
  if(typeof window !== 'undefined' && window.innerWidth <= 800 && window.innerHeight <= 600) {
     return true;
   } else {
     return false;
   }
}

const whatsAppLink = () => {
  return isMobileOrTablet() ? 'api' : 'web'
}

const Share = props => {
  const { title, excerpt, slug } = props
  const url = slug.href

  return (
    <Container>
      <ShareTitle>Why not share this with a friend?</ShareTitle>
      <Button
        type="whatsapp"
        size="medium"
        href={'https://' + whatsAppLink() + '.whatsapp.com/send' + `?text=${url}`}
        title="Share on Linkedin"
        target="_blank"
      >
        <img
          src="https://icongr.am/fontawesome/whatsapp.svg?color=ffffff"
          title="Share on Linkedin"
          alt="img"
        />
      </Button>
      <Button
        type="primary"
        size="medium"
        href={`https://twitter.com/intent/tweet?text=${title} by Level Studios: ${url}`}
        title="Share on Twitter"
        target="_blank"
      >
        <img
          src="https://icongr.am/fontawesome/twitter.svg?color=ffffff"
          title="Share on Twitter"
          alt="img"
        />
      </Button>
      <Button
        type="linkedin"
        size="medium"
        href={`http://www.linkedin.com/shareArticle?url=${url}&isFramed=true`}
        title="Share on Linkedin"
        target="_blank"
      >
        <img
          src="https://icongr.am/fontawesome/linkedin.svg?color=ffffff"
          title="Share on Linkedin"
          alt="img"
        />
      </Button>
      <Button
        type="reddit"
        size="medium"
        href={'https://www.reddit.com/submit' + '?' + `url=${url}` + '&' + `title=${title}`}
        title="Share on Reddit"
        target="_blank"
      >
        <img
          src="https://icongr.am/fontawesome/reddit.svg?color=ffffff"
          title="Share on Reddit"
          alt="img"
        />
      </Button>
    </Container>
  )
}

Share.propTypes = {
  title: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired
}

export default Share
